import { media, styled } from '@pulse-web-ui/theme';

import { HEADER_HEIGHT } from '@src/constants';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${HEADER_HEIGHT};
  background: ${({ theme }) => theme.colors.surface.primary};
  box-sizing: border-box;
  padding: 0 16px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  ${media.desktop} {
    padding: 0 24px;
  }
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Separator = styled.div`
  height: 14px;
  width: 1px;
  background: ${({ theme }) => theme.colors.icon.quaternary};
`;
