import { InsuranceSubProduct } from '@src/types';

export const getCheckedSubobjects = (
  oldSubobjects: InsuranceSubProduct[],
  newSubobjects: InsuranceSubProduct[]
) => {
  let hasChanged = false;
  const checkedSubobjects: InsuranceSubProduct[] = oldSubobjects.map(
    (oldSubobject) => {
      const newSubobject =
        newSubobjects.find(({ code }) => code === oldSubobject.code) ||
        oldSubobject;
      const insuranceSum = Number(oldSubobject.defaultInsuranceSum);
      const minLimit = Number(newSubobject.minLimit);
      const maxLimit = Number(newSubobject.maxLimit);

      if (insuranceSum < minLimit) {
        hasChanged = true;

        return {
          ...newSubobject,
          defaultInsuranceSum: minLimit.toString(),
        };
      } else if (insuranceSum > maxLimit) {
        hasChanged = true;

        return {
          ...newSubobject,
          defaultInsuranceSum: maxLimit.toString(),
        };
      } else {
        return { ...oldSubobject };
      }
    }
  );

  return {
    hasChanged,
    checkedSubobjects,
  };
};
