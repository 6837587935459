import { useContext, useEffect } from 'react';

import { OrderActionTypes, Store } from '@src/store';
import { OrderRequestData } from '@src/types';
import { getAddress, prepareDate, subObjectConverter } from '@src/utils';

export const useIflOrderHouseData = () => {
  const {
    state: {
      stateFormIFLHouse: {
        selectedIProduct,
        dadaValue,
        insuranceSubproducts,
        selectedDate,
        promoCode,
        insuranceSum,
        selectedDuration,
        risks,
        selectedBuildingMaterialCode,
        getPrices,
      },
    },
    dispatch,
  } = useContext(Store);

  const addressData = getAddress(dadaValue);

  const orderData: OrderRequestData = {
    productCode: selectedIProduct?.code,
    contractDuration: selectedDuration,
    risks: risks
      ?.filter((item) => item.active === true)
      .map((item) => item.code),
    effectiveSince: prepareDate(selectedDate),
    insuranceSum: String(insuranceSum),
    personProperties: {
      subObjects:
        insuranceSubproducts?.subObjects &&
        subObjectConverter(insuranceSubproducts.subObjects),
      address: addressData,
    },
    ...(promoCode &&
      getPrices?.isSuccessfulPromo !== false && { promoCode: promoCode }),
    buildingMaterial: selectedBuildingMaterialCode,
  };

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderRequestData,
      payload: orderData,
    });
  }, [
    selectedIProduct,
    dadaValue,
    insuranceSubproducts,
    selectedDate,
    promoCode,
    insuranceSum,
    selectedDuration,
    risks,
    getPrices?.isSuccessfulPromo,
  ]);

  return orderData;
};
