import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumericInput } from '@pulse-web-ui/numeric-input';

import {
  Container,
  HeaderAdaptive3,
  HeaderSub,
  SiteAgreements,
  Skeleton,
} from '@src/components';
import {
  AdaptiveContainerInverse,
  NumericInputBox,
  NumericInputContainer,
  NumericInputWrapper,
} from '@src/components/container';
import {
  FormHead,
  FormSubhead,
  FormSubheadListItem,
  NumericInputLabel,
} from '@src/components/form-elements';
import '@src/components/headers';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { USER_AGREEMENTS_URL, analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import {
  useBlockNextStep,
  useHandlePressKey,
  useNextStep,
  useRequest,
  useValidateProfileAuth,
} from '@src/hooks';
import { insurePersonsFormatter } from '@src/pages/ns-form/utils';
import { AuthActionTypes, Store } from '@src/store';
import type { AgeRatio } from '@src/store/ns';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode, NumberInsurePersons } from '@src/types';
import { ageRatiosFormat } from '@src/utils/age-ratios-format';

import { useNsDraft } from './hooks';

export const FormNsQuantity: FC = memo(() => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormNS: { numberInsurePersons, insuranceProduct, ageRatios },
      stateWizard: { wantNextStep, updateFormState },
      stateAuth: { authTokens },
      stateUser: { profile },
    },
    dispatch,
  } = useContext(Store);
  const [selectPersonsError, setSelectPersonsError] = useState(false);
  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, []);

  const totalNumberInsurePersons = useMemo(
    () =>
      Object.values(numberInsurePersons).reduce((sum, value) => sum + value, 0),
    [numberInsurePersons]
  );

  const isDisabledIncrement = useMemo(() => {
    if (insuranceProduct && insuranceProduct.maxObjectsNumber !== null) {
      return totalNumberInsurePersons >= insuranceProduct.maxObjectsNumber;
    }
    return false;
  }, [totalNumberInsurePersons, insuranceProduct]);

  const {
    res: ageRatioRes,
    isLoading,
    error,
    refetch,
  } = useRequest('formNSGetAgeRatio', 'post', '/v1/references/get-age-ratio');

  useRequest(
    'scoringRequest',
    'post',
    '/v1/scoring',
    {
      productCode: insuranceProduct?.code,
    },
    [insuranceProduct?.code],
    false,
    authTokens?.authorization?.accessToken
  );

  const ageRatiosLabels = useMemo(
    () => ageRatiosFormat(ageRatios),
    [ageRatios]
  );

  const storeInsuranceAgeRatio = useCallback((data: AgeRatio[]) => {
    dispatch({
      type: NSActionTypes.SetAgeRatios,
      payload: data,
    });
  }, []);

  const storeNumberInsurePersons = useCallback((data: NumberInsurePersons) => {
    const newInsurePersons = insurePersonsFormatter(data);

    dispatch({
      type: NSActionTypes.SetNumberInsurePersons,
      payload: data,
    });

    dispatch({
      type: NSActionTypes.SetInsurePersons,
      payload: newInsurePersons,
    });
  }, []);

  const { control, getValues, reset, watch } = useForm<NumberInsurePersons>({
    defaultValues: numberInsurePersons,
  });

  const validatePage = useCallback(() => {
    return totalNumberInsurePersons !== 0 && !selectPersonsError;
  }, [totalNumberInsurePersons, selectPersonsError]);

  const handleViewAgreement = () => {
    sendAnalyticEvent(analyticEvents.userAgreement);
  };

  const handleKeyPressEnter = () => {
    const currentValues = getValues();
    const v = Object.values(currentValues).filter((i) => i !== 0);
    if (v.length > 0) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    } else {
      setSelectPersonsError(true);
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  useNextStep(validatePage);
  useNsDraft();

  useEffect(() => {
    if (!isLoading) {
      reset();
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/ns',
    });
  }, []);

  useEffect(() => {
    if (!isLoading && ageRatioRes && (!ageRatios || ageRatios.length === 0)) {
      storeInsuranceAgeRatio(ageRatioRes.ageRatios);
    }
  }, [ageRatioRes]);

  useEffect(() => {
    const subscription = watch(() => storeNumberInsurePersons(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (profile?.profile?.lastName) {
      sendAnalyticEvent(analyticEvents.startAccident);
    }
  }, [profile?.profile?.lastName]);

  useEffect(() => {
    if (wantNextStep && !totalNumberInsurePersons) {
      setSelectPersonsError(true);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [wantNextStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, [updateFormState]);

  useEffect(() => {
    if (selectPersonsError && totalNumberInsurePersons) {
      setSelectPersonsError(false);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [totalNumberInsurePersons]);

  useBlockNextStep(profileIsLoading, profileError);

  if (isLoading || profileIsLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  if (profileError) {
    return (
      <GlobalErrorInfo
        pending={profileIsLoading}
        retrayHandler={profileRefetch}
      />
    );
  }

  return (
    <AdaptiveContainerInverse>
      <Container>
        <FormHead>{t('NS_FORM:headers.whoDoYouWantToProtect')}</FormHead>
        <FormSubheadListItem isError={selectPersonsError}>
          {t('NS_FORM:hints.selectNumberOfInsured')}
        </FormSubheadListItem>
        <FormSubhead>
          {insuranceProduct?.maxObjectsNumber &&
            t('NS_FORM:hints.youCanInsureUp.title', {
              count: insuranceProduct.maxObjectsNumber,
            })}
        </FormSubhead>
        <NumericInputContainer>
          <NumericInputWrapper>
            <HeaderAdaptive3>
              <span>{t('NS_FORM:labels.children')}</span>
            </HeaderAdaptive3>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.children}</NumericInputLabel>
              <Controller
                control={control}
                name="numberChildren"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
          </NumericInputWrapper>
          <NumericInputWrapper>
            <HeaderAdaptive3>
              <span>{t('NS_FORM:labels.adults')}</span>
              <HeaderSub>{t('NS_FORM:hints.includingYou')}</HeaderSub>
            </HeaderAdaptive3>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.adults}</NumericInputLabel>
              <Controller
                control={control}
                name="numberAdults"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
            <NumericInputBox>
              <NumericInputLabel>{ageRatiosLabels?.elderly}</NumericInputLabel>
              <Controller
                control={control}
                name="numberElderly"
                render={({ field }) => (
                  <NumericInput
                    readOnly
                    disabledIncrement={isDisabledIncrement}
                    {...field}
                  />
                )}
              />
            </NumericInputBox>
          </NumericInputWrapper>
        </NumericInputContainer>
      </Container>
      <SiteAgreements>
        {t('COMMON:hints.acceptTerms')}{' '}
        <a
          target="_blank"
          href={USER_AGREEMENTS_URL}
          onClick={handleViewAgreement}
          download
        >
          {t('COMMON:buttons.userAgreement')}
        </a>
      </SiteAgreements>
    </AdaptiveContainerInverse>
  );
});
