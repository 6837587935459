/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { UserStoreState } from './index';
import { UserActionTypes } from './index';

export type UserStoreReducer = (
  state: UserStoreState,
  action: StoreActions
) => UserStoreState;

export const userStoreReducer: UserStoreReducer = (state, action) => {
  switch (action?.type) {
    case UserActionTypes.SetProfile:
      return {
        ...state,
        profile: action.payload,
      };
    case UserActionTypes.SetUserRegistrationData:
      return {
        ...state,
        userRegistrationData: action.payload,
      };
    case UserActionTypes.SetCachedPersonalInfoData:
      return {
        ...state,
        cachedPersonalInfoData: action.payload,
      };
    case UserActionTypes.SetCachedPhoneNumber:
      return {
        ...state,
        cachedPhoneNumber: action.payload,
      };
    case UserActionTypes.SetCachedRegistrationFormData:
      return {
        ...state,
        cachedRegistrationFormData: action.payload,
      };
    case UserActionTypes.SetRegUtm:
      return {
        ...state,
        regUtm: action.payload,
      };
    case UserActionTypes.SetCid:
      return {
        ...state,
        cid: action.payload,
      };
    case UserActionTypes.SetIsSubscriptionExists:
      return {
        ...state,
        isSubscriptionExists: action.payload,
      };
    case UserActionTypes.SetSelectedProduct:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case UserActionTypes.SetCachedProduct:
      return {
        ...state,
        cachedProduct: action.payload,
      };
    case UserActionTypes.SetIsPureSubscription:
      return {
        ...state,
        isPureSubscription: action.payload,
      };
    case UserActionTypes.SetCachedDadaValue:
      return {
        ...state,
        cachedDadaValue: action.payload,
      };
    case UserActionTypes.SetAuthenticationRefetchUid:
      return {
        ...state,
        authenticationRefetchUid: action.payload,
      };
    case UserActionTypes.SetIsScrinning:
      return {
        ...state,
        isScrinning: action.payload,
      };
    case UserActionTypes.SetPreset:
      return {
        ...state,
        preset: action.payload,
      };
    case UserActionTypes.SetPromoCodeFailedMessage:
      return {
        ...state,
        promoCodeFailedMessage: action.payload,
      };
    case UserActionTypes.SetClientCategoryFromUrl:
      return {
        ...state,
        clientCategoryFromUrl: action.payload,
      };
    case UserActionTypes.SetRegionFiasIdFromUrl:
      return {
        ...state,
        regionFiasIdFromUrl: action.payload,
      };
    case UserActionTypes.SetAgentLogin:
      return {
        ...state,
        agentLogin: action.payload,
      };
    default:
      return state;
  }
};
