/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';

import { Checkbox } from '@pulse-web-ui/checkbox';
import { DocumentTransparent } from '@pulse-web-ui/icons';

import { PageBackButton, StyledButton } from '@src/common-components/button';
import {
  ArrowLeftIcon2,
  Container,
  DocumentsWrapper,
  OrderTitle,
  OrderWrapper,
  Skeleton,
} from '@src/components';
import { OrderWrapperTitle } from '@src/components/container';
import { sendAnalyticEvent } from '@src/components/web-analytic';
import {
  ClientDocumentType,
  FRACTION_TWO,
  INSURANCE_POLICIES,
  PAYMENT_ROUTE,
  Product,
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  miteRoute,
  nonSubscriptionProducts,
  nsRoute,
  petsRoute,
  sportNsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { OrderActionTypes, Store } from '@src/store';
import { KeyCode, OrderItemValueType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getFormPeriodLabels,
  handleAnalyticsEvents,
} from '@src/utils';

import { ClientDocumentItem } from './components/client-document-item';
import {
  OrderCheckoutAggrWrapper,
  OrderCheckoutTitleWrapper,
} from './components/components.styles';
import { OrderCheckoutItem } from './components/order-checkout-item';

type ContractDataType = {
  amount?: string | number;
  contractId?: string;
  email?: string | null;
  operationType: 'ORDER';
  orderNumber?: string;
  paymentDocumentId?: string;
  subscriptionId?: string;
  subscriptionType?: string;
};

export const OrderCheckout: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: {
        selectedDuration: selectedDurationIflHouse,
        presetData: housePresetData,
      },
      stateFormIFLFlat: { selectedDuration: selectedDurationIflFlat },
      stateFormNS: { selectedDuration: selectedDurationNS },
      stateFormPets: { selectedDuration: selectedDurationPets, presetData },
      stateUser: { profile, selectedProduct, preset },
      stateOrder: { orderSubmitData, orderInitData },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
      stateUser,
    },
    dispatch,
  } = useContext(Store);
  const formPeriodLabels = getFormPeriodLabels();
  const schema = yup.object().shape({
    insurancePolicies: yup
      .bool()
      .oneOf([true], t('COMMON:errors.withoutAgreement') || ''),
  });

  const priceDescription = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
        return t('ORDER:labels.priceOfPayment');
      default:
        return t('ORDER:labels.costOfPayment');
    }
  }, [authorizeRefRoute]);

  const emailDescription = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
        return t('ORDER:labels.policySentValidAfterPayment');
      default:
        return t('ORDER:labels.policySentToEmail');
    }
  }, [authorizeRefRoute]);

  useEffect(() => {
    switch (authorizeRefRoute) {
      case iflHouseRoute:
        if (preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetStep4Docs);
        } else {
          sendAnalyticEvent(analyticEvents.iflStep8Docs);
          sendAnalyticEvent(analyticEvents.iflHouseStep8Docs);
        }
        break;
      case iflFlatRoute:
        sendAnalyticEvent(analyticEvents.iflStep8Docs);
        sendAnalyticEvent(analyticEvents.iflFlatStep8Docs);
        break;
      case petsRoute:
        const event = !!presetData
          ? analyticEvents.petToStepDocsPreset
          : analyticEvents.petToStepDocs;
        sendAnalyticEvent(event);
        break;
      case sportNsRoute:
        sendAnalyticEvent(analyticEvents.sportToStepDocs);
        break;
      case miteRoute:
        sendAnalyticEvent(analyticEvents.miteToStepDocs);
        break;
      default:
        break;
    }
  }, []);

  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ insurancePolicies: boolean }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      insurancePolicies: false,
    },
  });

  const clientDocuments = orderSubmitData?.clientDocument.map((document) => ({
    ...document,
    icon: DocumentTransparent,
  }));

  const handleViewAgreement = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.viewAgreementRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.viewAgreementAccident);
        break;
    }
  };

  const acceptTermsLabel = useMemo(() => {
    const isKidIncluded = clientDocuments
      ?.map((doc) => doc.clientDocumentType)
      .includes(ClientDocumentType.KEY_INFO_DOCUMENT);

    const acceptTerms = t(
      'ORDER:labels.acceptTermsWithKeyInformationDocumentCondition',
      {
        kid: isKidIncluded ? t('ORDER:labels.kid') : '',
      }
    );

    switch (selectedProduct) {
      case Product.SPORT:
      case Product.MITE:
        return acceptTerms;

      default:
        return (
          <Trans
            components={{
              a: (
                <a
                  href={INSURANCE_POLICIES}
                  onClick={handleViewAgreement}
                  target="_blank"
                />
              ),
            }}
          >
            {`${acceptTerms} ${t('ORDER:labels.nonAcceptanceAgreementLink')}`}
          </Trans>
        );
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (!orderSubmitData) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/'); // проверить
    }
  }, [orderSubmitData]);

  const orderResult = [
    {
      type: OrderItemValueType.SIMPLE,
      title: t('ORDER:labels.policyNumber'),
      value: `${orderSubmitData?.contractNumber}`, // проверить - с бэка в моковых данных не приходит contractNumber, только orderNumber
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: emailDescription,
      value: profile?.profile.email || '',
    },
  ];

  if (!nonSubscriptionProducts.includes(selectedProduct || '')) {
    orderResult.unshift({
      type: OrderItemValueType.SIMPLE,
      title: priceDescription,
      value: `${
        orderSubmitData?.premiumAndDelta
          ? `${currencyRuLocaleWithoutFraction(
              Number(orderSubmitData?.premiumAndDelta),
              FRACTION_TWO
            )}`
          : ''
      } ${
        formPeriodLabels.get(
          selectedDurationIflFlat ||
            selectedDurationIflHouse ||
            selectedDurationNS ||
            selectedDurationPets ||
            ''
        )?.label || t('COMMON:labels.perMonth')
      }`,
    });
  }

  const contractData: ContractDataType = {
    amount: orderSubmitData?.premiumAndDelta,
    contractId: orderSubmitData?.contractId,
    email: stateUser.profile?.profile?.email,
    operationType: 'ORDER',
    orderNumber: orderSubmitData?.orderNumber,
    paymentDocumentId: orderSubmitData?.billId,
    subscriptionId: orderSubmitData?.subscriptionId,
    subscriptionType: orderSubmitData?.subscriptionType,
  };

  const { isLoading, res, error, refetch } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    '/v1/payment/init-order',
    contractData,
    [orderInitData, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 2,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 2,
        });
        break;
    }

    navigate('/order-detail');
  };

  const submitPage = handleSubmit(() => {
    refetch();
  });

  const handleKeyPressEnter = () => submitPage();
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: OrderActionTypes.SetOrderInitData,
        payload: res,
      });

      if (res?.signature) {
        let data;

        if (authorizeRefRoute === petsRoute) {
          data = presetData;
        }

        if (authorizeRefRoute === iflHouseRoute) {
          data = housePresetData;
        }

        handleAnalyticsEvents.handleNavigateToPaymentAnalyticsEvents({
          authorizeRefRoute,
          presetData: data,
        });

        navigate(PAYMENT_ROUTE);
      }
    }
  }, [isLoading, res]);

  const Footer = useMemo(
    () => (
      <>
        <StyledButton
          label={t('ORDER:buttons.proceedToCheckout') || ''}
          variant="primary"
          onClick={submitPage}
        />
      </>
    ),
    [isLoading]
  );

  if (isLoading) return <Skeleton />;

  if (error) {
    const e = error?.response?.status;

    if (e !== 401) {
      return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
    }
  }

  return (
    <BaseLayout footer={Footer}>
      <PageBackButton
        variant="text"
        icon={<ArrowLeftIcon2 />}
        onClick={handleClickBack}
      >
        {t('COMMON:buttons.back')}
      </PageBackButton>
      <OrderCheckoutTitleWrapper>
        <Container>
          <OrderTitle>
            {nonSubscriptionProducts.includes(selectedProduct || '')
              ? t('ORDER:headers.policyPayment')
              : t('ORDER:headers.subscriptionPayment')}
          </OrderTitle>
        </Container>
      </OrderCheckoutTitleWrapper>
      <OrderWrapper>
        {orderResult?.map((orderItem) => (
          <OrderCheckoutItem
            key={`${orderItem.title} + ${orderItem.value}`}
            isColsIdentical={true}
            {...orderItem}
          />
        ))}
      </OrderWrapper>
      <Container>
        <OrderWrapperTitle>
          {t('ORDER:headers.documentsTitle')}
        </OrderWrapperTitle>
      </Container>
      <DocumentsWrapper>
        {clientDocuments?.map((clientDocumentItem) => (
          <ClientDocumentItem key={uuid()} {...clientDocumentItem} />
        ))}
      </DocumentsWrapper>
      <OrderCheckoutAggrWrapper>
        <Controller
          control={control}
          name="insurancePolicies"
          render={({ field: { onChange }, fieldState }) => (
            <Checkbox
              label={acceptTermsLabel}
              name="insurancePolicies"
              onChange={onChange}
              message={errors.insurancePolicies?.message}
              status={fieldState.error && 'error'}
            />
          )}
        />
      </OrderCheckoutAggrWrapper>
    </BaseLayout>
  );
};
