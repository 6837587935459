import { styled } from '@pulse-web-ui/theme';

import { HEADER_HEIGHT } from '@src/constants';

export const ClientOrderPageWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: calc(${HEADER_HEIGHT} + 16px);
  background: ${({ theme }) => theme.colors.surface.primary};
`;
