import type { Option } from '@pulse-web-ui/autocomplete-select';
import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import { DEFAULT_INSURANCE_SUM } from '@src/constants';
import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubproducts,
  Materials,
  Region,
  Risk,
  RiskPage,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

import { HousePresetData } from './ifl-house-store.types';

export type InsuranceSum = number;

export type SelectedIProduct = undefined | InsuranceProductType;

export interface IFLHouseStoreState {
  selectedIProduct?: InsuranceProductType;
  selectedRegion?: Region;
  regions?: Option[];
  risks?: Risk[];
  insuranceSubproducts?: InsuranceSubproducts;
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  getPricesFull: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  dadaValue?: DaDataAddressSuggestion;
  promoCode?: string;
  promoCodeApplyed?: string;
  risksPage: RiskPage;
  materials?: Materials;
  selectedBuildingMaterialCode?: string;
  fieldWithEmptyDependencies: string | undefined;
  emptyDependencies: string[] | undefined;
  isObjectDataError?: boolean | undefined;
  getSubobjectsRefetchUid?: string;
  currentSum: number;
  currentSumMinLimit: string;
  currentSumMaxLimit: string;
  currentSumErr: boolean;
  selectedId?: string;
  isSelectedCustomStreet: boolean;
  isSelectedCustomHouse: boolean;
  isEmptyStreetSuggestions: boolean;
  isEmptyHouseSuggestions: boolean;
  presetData?: HousePresetData;
}

export const initIFLHouseStoreState: IFLHouseStoreState = {
  selectedIProduct: undefined,
  selectedRegion: undefined,
  regions: undefined,
  risks: undefined,
  insuranceSubproducts: undefined,
  insuranceSum: DEFAULT_INSURANCE_SUM,
  getPrices: undefined,
  getPricesFull: undefined,
  selectedDuration: null,
  selectedDate: null,
  dadaValue: undefined,
  promoCode: undefined,
  promoCodeApplyed: undefined,
  risksPage: 'primary',
  materials: undefined,
  selectedBuildingMaterialCode: undefined,
  fieldWithEmptyDependencies: undefined,
  emptyDependencies: [],
  isObjectDataError: undefined,
  getSubobjectsRefetchUid: undefined,
  currentSum: 2_000_000,
  currentSumMinLimit: '0',
  currentSumMaxLimit: '0',
  currentSumErr: false,
  selectedId: undefined,
  isSelectedCustomStreet: false,
  isSelectedCustomHouse: false,
  isEmptyStreetSuggestions: false,
  isEmptyHouseSuggestions: false,
};
