import axios from 'axios';

const CPA_SCALEO_TOKEN = window.envKeys.CPA_SCALEO_TOKEN;
const BASE_URL = 'https://epromo.scaletrk.com/track/conv-addchange';

export const sendCpaScaleoAnalytic = (
  event: string,
  params: { cid: string }
) => {
  const { cid } = params;

  const url = `${BASE_URL}?click_id=${cid}&token=${CPA_SCALEO_TOKEN}&conv_status=${event}`;

  axios.get(url);
};
