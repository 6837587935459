import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { DocumentTransparent } from '@pulse-web-ui/icons';

import {
  Container,
  DocumentsWrapper,
  OrderTitle,
  OrderWrapper,
} from '@src/components';
import { OrderWrapperTitle } from '@src/components/container';
import { Header } from '@src/components/header';
import {
  ClientDocumentType,
  INSURANCE_POLICIES,
  PAYMENT_ROUTE,
  USER_RECL_AGREEMENTS_URL,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { OrderActionTypes, Store } from '@src/store';
import { OrderItemValueType } from '@src/types';

import { ClientOrderPageWrapper } from './client-order-page.styles';
import { ClientDocumentItem } from './components/client-document-item';
import {
  OrderCheckoutAggrWrapper,
  OrderCheckoutTitleWrapper,
} from './components/components.styles';
import { OrderCheckoutItem } from './components/order-checkout-item';

const MOCK_DOCUMENTS = [
  {
    clientDocument: '',
    clientDocumentType: ClientDocumentType.KEY_INFO_DOCUMENT,
    icon: DocumentTransparent,
  },
  {
    clientDocument: '',
    clientDocumentType: ClientDocumentType.CONTRACT,
    icon: DocumentTransparent,
  },
  {
    clientDocument: '',
    clientDocumentType: ClientDocumentType.RULES,
    icon: DocumentTransparent,
  },
  {
    clientDocument: '',
    clientDocumentType: ClientDocumentType.MEMO,
    icon: DocumentTransparent,
  },
];

const MOCK_ORDER_LIST = [
  {
    type: OrderItemValueType.SIMPLE,
    title: 'ФИО',
    value: 'Максимов Алексей Пешков',
  },
  {
    type: OrderItemValueType.SIMPLE,
    title: 'Стоимость и периодичность оплаты',
    value: '300,00 ₽ в месяц',
  },
  {
    type: OrderItemValueType.SIMPLE,
    title: 'Номер полиса',
    value: '08.150000V.626-1-1',
  },
  {
    type: OrderItemValueType.SIMPLE,
    title: 'Полис отправлен на почту',
    value: 'stasik@ya.ru',
  },
];

export const ClientOrderPage = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const schema = yup.object().shape({
    insurancePolicies: yup
      .bool()
      .oneOf([true], t('COMMON:errors.withoutAgreement') || ''),
    userPoliciesCode002: yup.bool(),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<{ insurancePolicies: boolean; userPoliciesCode002: boolean }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      insurancePolicies: false,
      userPoliciesCode002: false,
    },
  });
  const navigate = useNavigate();

  const { isLoading, res, error, refetch } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    '/v1/payment/init-order',
    // TODO: Добавить данные из запроса
    {},
    [authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: OrderActionTypes.SetOrderInitData,
        payload: res,
      });

      if (res?.signature) {
        navigate(PAYMENT_ROUTE);
      }
    }
  }, [isLoading, res]);

  const submitPage = handleSubmit(() => {
    refetch();
  });

  if (error) {
    const e = error?.response?.status;

    if (e !== 401) {
      return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
    }
  }

  return (
    <>
      <Header />
      <ClientOrderPageWrapper>
        <BaseLayout
          footer={
            <Button
              label={t('ORDER:buttons.proceedToCheckout') || ''}
              variant="primary"
              onClick={submitPage}
            />
          }
        >
          <OrderCheckoutTitleWrapper>
            <Container>
              <OrderTitle>{t('ORDER:headers.subscriptionPayment')}</OrderTitle>
            </Container>
          </OrderCheckoutTitleWrapper>
          <OrderWrapper>
            {MOCK_ORDER_LIST.map((orderItem) => (
              <OrderCheckoutItem
                key={orderItem.title}
                isColsIdentical={true}
                {...orderItem}
              />
            ))}
          </OrderWrapper>
          <Container>
            <OrderWrapperTitle>
              {t('ORDER:headers.documentsTitle')}
            </OrderWrapperTitle>
          </Container>
          <DocumentsWrapper>
            {MOCK_DOCUMENTS.map((document) => (
              <ClientDocumentItem
                key={`${document.clientDocument}_${document.clientDocumentType}`}
                {...document}
              />
            ))}
          </DocumentsWrapper>
          <OrderCheckoutAggrWrapper>
            <Controller
              control={control}
              name="insurancePolicies"
              render={({ field: { onChange }, fieldState }) => (
                <Checkbox
                  label={
                    <Trans
                      components={{
                        a: <a href={INSURANCE_POLICIES} target="_blank" />,
                      }}
                    >
                      {t(
                        'ORDER:labels.acceptTermsWithKeyInformationDocumentCondition',
                        { kid: '' }
                      )}{' '}
                      {t('ORDER:labels.nonAcceptanceAgreementLink')}
                    </Trans>
                  }
                  name="insurancePolicies"
                  onChange={onChange}
                  message={errors.insurancePolicies?.message}
                  status={fieldState.error && 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name="userPoliciesCode002"
              render={({ field: { onChange }, fieldState }) => (
                <Checkbox
                  label={
                    <Trans
                      values={{ href: USER_RECL_AGREEMENTS_URL }}
                      components={{ a: <a /> }}
                    >
                      {t('AUTH:hints.agreeReceiveSpecialOffers')}
                    </Trans>
                  }
                  name="userPoliciesCode002"
                  onChange={onChange}
                  message={errors.userPoliciesCode002?.message}
                  status={fieldState.error && 'error'}
                />
              )}
            />
          </OrderCheckoutAggrWrapper>
        </BaseLayout>
      </ClientOrderPageWrapper>
    </>
  );
};
