/* eslint-disable indent */
import { memo, useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { Product, insuranceProductsCode } from '@src/constants';
import { useRequest } from '@src/hooks';
import { Store } from '@src/store';

export const ScoringInterceptor: FC = memo(() => {
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: { selectedProduct, profile },
    },
  } = useContext(Store);

  const productCodeVal = useMemo(() => {
    switch (selectedProduct) {
      case Product.HOUSE:
        return insuranceProductsCode.house;
      case Product.APARTMENT:
        return insuranceProductsCode.flat;
      default:
        return null;
    }
  }, [selectedProduct]);

  const { refetch } = useRequest(
    'userScoringRequest',
    'post',
    '/v4/scoring/user',
    {
      productCode: productCodeVal,
    },
    [productCodeVal],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (profile?.profile.firstName && productCodeVal) {
      refetch();
    }
  }, [profile?.profile.firstName, productCodeVal]);

  return null;
});
